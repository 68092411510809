"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCookie = exports.capitalizeString = exports.trimAfterComma = void 0;
function trimAfterComma(val) {
    return val.split(',', 1)[0];
}
exports.trimAfterComma = trimAfterComma;
function capitalizeString(val) {
    const stringArray = val.toLowerCase().split(' ');
    return stringArray
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}
exports.capitalizeString = capitalizeString;
function getCookie(name) {
    if (typeof window === 'undefined' || !document || !document.cookie) {
        return null;
    }
    const xsrfCookies = document.cookie
        .split(';')
        .map(c => c.trim())
        .filter(c => c.startsWith(name + '='));
    if (xsrfCookies.length === 0) {
        return null;
    }
    return decodeURIComponent(xsrfCookies[0].split('=')[1]);
}
exports.getCookie = getCookie;
