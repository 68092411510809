"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const bootstrap_1 = require("bootstrap");
const Modal = ({ id, keyboard, openState, title, children, size, confirm, titleClose = true, position, style = {}, }) => {
    const modalRef = (0, react_1.useRef)();
    const [modal, setModal] = (0, react_1.useState)();
    const [open, setOpen] = openState || (0, react_1.useState)(false);
    const confirmCb = (0, react_1.useCallback)((e) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        (_a = modal === null || modal === void 0 ? void 0 : modal.hide) === null || _a === void 0 ? void 0 : _a.call(modal);
        yield (confirm === null || confirm === void 0 ? void 0 : confirm.callback(e));
    }), [modal]);
    (0, react_1.useEffect)(() => {
        var _a, _b, _c, _d;
        const show = 'show.bs.modal';
        const shown = 'shown.bs.modal';
        const hide = 'hide.bs.modal';
        const hidden = 'hidden.bs.modal';
        if (modalRef.current) {
            (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener(show, function () {
                setOpen(true);
            });
            (_b = modalRef.current) === null || _b === void 0 ? void 0 : _b.addEventListener(shown, function () { });
            (_c = modalRef.current) === null || _c === void 0 ? void 0 : _c.addEventListener(hide, function () { });
            (_d = modalRef.current) === null || _d === void 0 ? void 0 : _d.addEventListener(hidden, function () {
                setOpen(false);
            });
            setModal(new bootstrap_1.Modal(modalRef.current, {
                keyboard: !!keyboard,
                backdrop: titleClose ? true : 'static',
            }));
            return () => {
                var _a, _b, _c, _d, _e;
                (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener(shown, () => { });
                (_b = modalRef.current) === null || _b === void 0 ? void 0 : _b.removeEventListener(hidden, () => { });
                (_c = modalRef.current) === null || _c === void 0 ? void 0 : _c.removeEventListener(show, () => { });
                (_d = modalRef.current) === null || _d === void 0 ? void 0 : _d.removeEventListener(hide, () => { });
                (_e = modal === null || modal === void 0 ? void 0 : modal.dispose) === null || _e === void 0 ? void 0 : _e.call(modal);
            };
        }
    }, []);
    let customClass;
    switch (position) {
        case 'centered':
            customClass = 'modal-dialog-centered';
            break;
        case 'bottom':
            customClass = 'modal-dialog-end';
            break;
        default:
            customClass = 'modal-dialog';
            break;
    }
    (0, react_1.useEffect)(() => {
        if (!!open) {
            modal === null || modal === void 0 ? void 0 : modal.show();
        }
        else {
            modal === null || modal === void 0 ? void 0 : modal.hide();
        }
    }, [open]);
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: "modal fade", id: id, "data-bs-target": `modal-${id}`, tabIndex: -1, "aria-labelledby": id + ' modal', "aria-modal": "true", role: "dialog", ref: (ref) => {
            modalRef.current = ref;
        }, style: style }, { children: (0, jsx_runtime_1.jsx)("div", Object.assign({ id: `modal-${id}`, className: `modal-dialog ${customClass} modal-${size ? size : ''}` }, { children: (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "modal-content" }, { children: [(title || titleClose) && ((0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "modal-header" }, { children: [(0, jsx_runtime_1.jsx)("h5", Object.assign({ className: "modal-title", id: id + ' modal' }, { children: title })), titleClose && ((0, jsx_runtime_1.jsx)("button", { type: "button", className: "btn-close", "data-bs-dismiss": "modal", "aria-label": "Close" }))] }))), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: "modal-body d-flex flex-column align-items-center" }, { children: open ? children : null })), (confirm === null || confirm === void 0 ? void 0 : confirm.callback) && ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: "modal-footer" }, { children: (0, jsx_runtime_1.jsx)("button", Object.assign({ type: "button", className: "btn btn-sm btn-primary", onClick: confirmCb }, { children: (confirm === null || confirm === void 0 ? void 0 : confirm.label) ? confirm.label : 'Confirm' })) })))] })) })) })));
};
exports.default = Modal;
