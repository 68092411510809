"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOC_TYPE = void 0;
exports.DOC_TYPE = {
    ASSET_MANAGEMENT: 'Asset Management Agreement',
    DEED: 'Deed',
    ENTITY_DOCUMENTS: 'Entity Documents',
    FINANCIAL_STATEMENT: 'Financial Statement',
    INSPECTION_REPORT: 'Inspection Report',
    INSURANCE: 'Insurance Bill',
    LEASE_AGREEMENT: 'Lease Agreement',
    PROPERTY_INSURANCE: 'Property Insurance',
    PROPERTY_MANAGEMENT_AGREEMENT: 'Property Management Agreement',
    PROPERTY_REPORT: 'Property Report',
    PROPERTY_TAX: 'Property Tax Bill',
    PURCHASE_SALE_CONTRACT: 'Purchase & Sale Contract',
    RENTAL_RIDER: 'Rental Rider',
    REPAIR: 'Repairs Bill',
    TAX_ID: 'Tax ID',
    TITLE_COMMITMENT: 'Title Commitment',
    TITLE_INSURANCE: 'Title Insurance',
    TITLE_REPORT: 'Title Report',
    TRUST_AGREEMENT: 'Trust Agreement',
    TRUST_CERTIFICATE: 'Trust Certificates',
    UNIT_SETTLEMENT: 'Unit Settlement Statement',
    UTILITY: 'Utilities Bill',
    OTHER_DOCUMENT: 'Other',
};
