"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Formatter {
    static toUSD(num, digitOvrd = 0, currencySign = 'accounting') {
        if (typeof num === 'number') {
            const numberFormat = new Intl.NumberFormat('en-us', {
                style: 'currency',
                currency: 'USD',
                currencySign: currencySign,
                maximumFractionDigits: digitOvrd,
            });
            return numberFormat.format(num);
        }
        return num
            .toRoundedUnit(0, 'HALF_AWAY_FROM_ZERO')
            .toLocaleString('en-us', {
            style: 'currency',
            currencySign: 'accounting',
            currency: 'USD',
            maximumFractionDigits: digitOvrd,
        });
    }
    static toPercent(num, min, max) {
        return num.toLocaleString('en-us', {
            style: 'percent',
            minimumFractionDigits: min,
            maximumFractionDigits: max,
        });
    }
    static byType(value, type) {
        const searchVal = /[$,]/g;
        const newVal = '';
        const minFraction = 1;
        const maxFraction = 1;
        if (type === 'percent') {
            return this.toPercent(parseFloat(value), minFraction, maxFraction);
        }
        if (type === 'fixed-dollar') {
            return this.toUSD(parseFloat(value.replace(searchVal, newVal)));
        }
        if (type === 'fixed-dollar-standard') {
            return this.toUSD(parseFloat(value.replace(searchVal, newVal)), 0, 'standard');
        }
        return parseFloat(value).toLocaleString();
    }
    static byPlusMinus(num) {
        const numberFormat = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            signDisplay: 'always',
            maximumFractionDigits: 0,
        });
        return numberFormat.format(num);
    }
}
exports.default = Formatter;
