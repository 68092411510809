"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useTriggerModal() {
    const openState = (0, react_1.useState)(false);
    const [, setOpenState] = openState;
    const trigger = (0, react_1.useCallback)(() => {
        setOpenState(true);
    }, []);
    return { openState, trigger };
}
exports.default = useTriggerModal;
