"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const bootstrap_1 = require("bootstrap");
const R = __importStar(require("ramda"));
const DropdownFilter = ({ id, options, autoClose = true, onSelection = () => { }, onReset = () => { }, buttonClassName = '', label = '', menuClassName = '', menuAlignment, style = {} }) => {
    const dropdownRef = (0, react_1.useRef)();
    const [dropdown, setDropdown] = (0, react_1.useState)();
    const noOptionsSelected = (0, react_1.useMemo)(() => {
        let i = 1;
        const optionPairs = R.toPairs(options);
        optionPairs.forEach(([, option]) => {
            if (option.selected) {
                i *= 0;
            }
        });
        return !!i;
    }, [options]);
    (0, react_1.useEffect)(() => {
        var _a, _b, _c, _d;
        if (dropdownRef.current) {
            (_a = dropdownRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('show.bs.dropdown', function () { });
            (_b = dropdownRef.current) === null || _b === void 0 ? void 0 : _b.addEventListener('shown.bs.dropdown', function () { });
            (_c = dropdownRef.current) === null || _c === void 0 ? void 0 : _c.addEventListener('hide.bs.dropdown', function () { });
            (_d = dropdownRef.current) === null || _d === void 0 ? void 0 : _d.addEventListener('hidden.bs.dropdown', function () { });
            return () => {
                var _a, _b, _c, _d, _e;
                (_a = dropdownRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('shown.bs.dropdown', () => { });
                (_b = dropdownRef.current) === null || _b === void 0 ? void 0 : _b.removeEventListener('hidden.bs.dropdown', () => { });
                (_c = dropdownRef.current) === null || _c === void 0 ? void 0 : _c.removeEventListener('show.bs.dropdown', () => { });
                (_d = dropdownRef.current) === null || _d === void 0 ? void 0 : _d.removeEventListener('hide.bs.dropdown', () => { });
                (_e = dropdown === null || dropdown === void 0 ? void 0 : dropdown.dispose) === null || _e === void 0 ? void 0 : _e.call(dropdown);
            };
        }
    }, []);
    (0, react_1.useEffect)(() => {
        if (dropdownRef === null || dropdownRef === void 0 ? void 0 : dropdownRef.current) {
            setDropdown(new bootstrap_1.Dropdown(dropdownRef.current, {
                autoClose
            }));
        }
    }, []);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("button", Object.assign({ ref: ref => {
                    dropdownRef.current = ref;
                }, id: id, className: `dropdown-toggle bg-white ${buttonClassName}`, type: "button", "data-bs-toggle": "dropdown", "aria-expanded": "false", style: style }, { children: label })), (0, jsx_runtime_1.jsxs)("ul", Object.assign({ className: `dropdown-menu ${menuAlignment ? `dropdown-menu-${menuAlignment}` : ''} ${menuClassName}`, "aria-labelledby": id }, { children: [Object.entries(options).map(([id, { label, selected }]) => {
                        return ((0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsxs)("label", Object.assign({ className: "dropdown-item small" }, { children: [(0, jsx_runtime_1.jsx)("input", { className: "form-check-input me-1", type: "checkbox", onChange: onSelection, checked: selected, name: id }), (0, jsx_runtime_1.jsx)("span", Object.assign({ className: "text-muted" }, { children: label }))] })) }, `dropdown-option-${id}`));
                    }), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: "d-grid" }, { children: (0, jsx_runtime_1.jsx)("button", Object.assign({ type: "button", className: `btn btn-sm btn-primary mx-3 mt-3 mb-2 ${noOptionsSelected ? 'btn-light disabled' : ''}`, disabled: noOptionsSelected, onClick: onReset }, { children: "Clear Filters" })) }))] }))] }));
};
exports.default = DropdownFilter;
