"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const mime_types_1 = __importStar(require("mime-types"));
const constants_1 = require("@kepler/config/constants");
const types_1 = require("@kepler/types");
function useDocumentInfo(document) {
    var _a;
    const fileExt = (0, mime_types_1.extension)(document.mime_type);
    const icon = (_a = constants_1.MIME_TYPE_ICONS[mime_types_1.default.lookup(fileExt || '')]) !== null && _a !== void 0 ? _a : 'file-earmark';
    const documentTypeKey = document.document_type;
    const docType = types_1.DOC_TYPE.hasOwnProperty(documentTypeKey)
        ? types_1.DOC_TYPE[documentTypeKey]
        : types_1.DOC_TYPE.OTHER_DOCUMENT;
    const docTag = (docType) => {
        switch (docType) {
            case 'Unit Settlement Statement':
                return ((0, jsx_runtime_1.jsx)("span", Object.assign({ className: "px-2 fw-medium mice rounded text-bg-blue-300" }, { children: docType })));
            case 'Deed':
                return ((0, jsx_runtime_1.jsx)("span", Object.assign({ className: "px-2 fw-medium mice rounded text-bg-pink-300" }, { children: docType })));
            case 'Rental Rider':
                return ((0, jsx_runtime_1.jsx)("span", Object.assign({ className: "px-2 fw-medium mice rounded text-bg-pink-300" }, { children: docType })));
            case 'Trust Agreement':
                return ((0, jsx_runtime_1.jsx)("span", Object.assign({ className: "px-2 fw-medium mice rounded text-bg-blue-300" }, { children: docType })));
            case 'Property Management Agreement':
                return ((0, jsx_runtime_1.jsx)("span", Object.assign({ className: "px-2 fw-medium mice rounded text-bg-blue-300" }, { children: docType })));
            case 'Property Tax Bill':
                return ((0, jsx_runtime_1.jsx)("span", Object.assign({ className: "px-2 fw-medium mice rounded text-bg-cyan-300" }, { children: docType })));
            case 'Insurance Bill':
                return ((0, jsx_runtime_1.jsx)("span", Object.assign({ className: "px-2 fw-medium mice rounded text-bg-cyan-300" }, { children: docType })));
            case 'Utilities Bill':
                return ((0, jsx_runtime_1.jsx)("span", Object.assign({ className: "px-2 fw-medium mice rounded text-bg-cyan-300" }, { children: docType })));
            case 'Repairs Bill':
                return ((0, jsx_runtime_1.jsx)("span", Object.assign({ className: "px-2 fw-medium mice rounded text-bg-cyan-300" }, { children: docType })));
            case 'Financial Statement':
                return ((0, jsx_runtime_1.jsx)("span", Object.assign({ className: "px-2 fw-medium mice rounded text-bg-cyan-300" }, { children: docType })));
            default:
                return ((0, jsx_runtime_1.jsx)("span", Object.assign({ className: "px-2 fw-medium mice rounded text-bg-yellow-300" }, { children: docType })));
        }
    };
    return { fileExt, icon, docType, docTag };
}
exports.default = useDocumentInfo;
