"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const Slider = ({ children }) => {
    const [currentSlide, setCurrentSlide] = (0, react_1.useState)(0);
    const slideCount = react_1.Children.count(children);
    const sliderControls = (0, react_1.useCallback)((direction) => {
        if (direction === 'previous') {
            setCurrentSlide(prevSlide => (prevSlide + 1) % slideCount);
        }
        if (direction === 'next') {
            setCurrentSlide(prevSlide => (prevSlide - 1 + slideCount) % slideCount);
        }
    }, [currentSlide]);
    const slides = react_1.Children.toArray(children);
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: "row position-relative" }, { children: (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "col-12" }, { children: [(0, jsx_runtime_1.jsx)("button", Object.assign({ className: "position-absolute start-0 top-50 btn bg-silver border border-platinum rounded-circle", onClick: () => sliderControls('previous') }, { children: (0, jsx_runtime_1.jsx)("i", { className: "bi bi-caret-left-fill text-gray-400" }) })), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: "container d-flex justify-content-center" }, { children: (0, jsx_runtime_1.jsx)("div", Object.assign({ className: "col py-5 px-4 py-lg-3 px-lg-8 shadow-lg d-flex flex-column flex-md-row align-items-center rounded bg-white" }, { children: slides[currentSlide] })) })), (0, jsx_runtime_1.jsx)("button", Object.assign({ className: "position-absolute end-0 top-50 btn bg-silver border border-platinum rounded-circle", onClick: () => sliderControls('next') }, { children: (0, jsx_runtime_1.jsx)("i", { className: "bi bi-caret-right-fill text-gray-400" }) }))] })) })));
};
exports.default = Slider;
