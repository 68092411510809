"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const AccordionContext_1 = require("./AccordionContext");
const AccordionProvider = ({ children }) => {
    const [activeId, setActiveId] = (0, react_1.useState)(null);
    return ((0, jsx_runtime_1.jsx)(AccordionContext_1.AccordionContext.Provider, Object.assign({ value: { activeId, setActiveId } }, { children: children })));
};
exports.default = AccordionProvider;
