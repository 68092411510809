"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardFooter = exports.CardImage = exports.CardText = exports.CardBody = exports.CardTitle = exports.CardHeader = exports.CardMeta = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Card = ({ children, className = '', isColumnWrapped = false, columnClassName, style = {} }) => {
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: isColumnWrapped ? ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: `col ${columnClassName}` }, { children: (0, jsx_runtime_1.jsx)("div", Object.assign({ className: `card ${className}`, style: style }, { children: children })) }))) : ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: `card ${className}` }, { children: children }))) }));
};
const CardMeta = ({ children, className, title }) => {
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: `d-flex justify-content-between ${className}` }, { children: [(0, jsx_runtime_1.jsx)("div", Object.assign({ className: "col small" }, { children: title })), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: "col small text-end fw-bold" }, { children: children }))] })) }));
};
exports.CardMeta = CardMeta;
const CardHeader = ({ children, className }) => {
    return (0, jsx_runtime_1.jsx)("div", Object.assign({ className: `card-header ${className}` }, { children: children }));
};
exports.CardHeader = CardHeader;
const CardTitle = ({ children, className }) => {
    return (0, jsx_runtime_1.jsx)("h5", Object.assign({ className: `card-title ${className}` }, { children: children }));
};
exports.CardTitle = CardTitle;
const CardBody = ({ children, className }) => {
    return (0, jsx_runtime_1.jsx)("div", Object.assign({ className: `card-body ${className}` }, { children: children }));
};
exports.CardBody = CardBody;
const CardText = ({ children, className }) => {
    return (0, jsx_runtime_1.jsx)("div", Object.assign({ className: `card-text ${className}` }, { children: children }));
};
exports.CardText = CardText;
const CardImage = ({ children, className }) => {
    return (0, jsx_runtime_1.jsx)("div", Object.assign({ className: `card-image ${className}` }, { children: children }));
};
exports.CardImage = CardImage;
const CardFooter = ({ children, className }) => {
    return (0, jsx_runtime_1.jsx)("div", Object.assign({ className: `card-footer ${className}` }, { children: children }));
};
exports.CardFooter = CardFooter;
exports.default = Card;
