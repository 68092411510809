"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function signupEmailAutofill() {
    (0, react_1.useEffect)(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const emailInput = document.getElementById('id_email');
        const emailValue = emailInput.value.trim();
        const emailParams = urlParams.get('email');
        const passwordField = document.getElementById('id_password1');
        if (emailValue !== '' && emailParams !== '') {
            passwordField === null || passwordField === void 0 ? void 0 : passwordField.focus();
        }
    }, []);
}
exports.default = signupEmailAutofill;
