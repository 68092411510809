"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleInput = exports.handleDelete = exports.handleClipboard = void 0;
function handleClipboard(inputs) {
    return function (event) {
        const { target, clipboardData } = event;
        const clipboardValue = clipboardData === null || clipboardData === void 0 ? void 0 : clipboardData.getData('text').toUpperCase();
        event.preventDefault();
        if (target.localName !== 'input') {
            return;
        }
        if (typeof clipboardValue === 'string') {
            const regexTest = /^\d{6}$/.test(clipboardValue);
            if (!regexTest) {
                event.preventDefault();
                return;
            }
            inputs.forEach((input, index) => {
                input.focus();
                input.value = (clipboardValue === null || clipboardValue === void 0 ? void 0 : clipboardValue.charAt(index)) || '';
            });
        }
        return;
    };
}
exports.handleClipboard = handleClipboard;
function handleDelete(index, inputs) {
    return function (event) {
        const input = event.target;
        const previousInputField = inputs[index - 1];
        const backspacePressed = event.key === 'Backspace';
        const currentFieldIsEmpty = input.value === '';
        if (backspacePressed && currentFieldIsEmpty) {
            previousInputField === null || previousInputField === void 0 ? void 0 : previousInputField.focus();
        }
    };
}
exports.handleDelete = handleDelete;
function handleInput(index, inputs) {
    return function (event) {
        const input = event.target;
        input.value = input.value.toUpperCase();
        const nextInputField = inputs[index + 1];
        const currentFieldIsNotEmpty = input.value !== '';
        if (nextInputField && currentFieldIsNotEmpty) {
            nextInputField.focus();
        }
    };
}
exports.handleInput = handleInput;
