"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableSectionHeader = exports.TableSectionTotal = exports.TableHeader = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const constants_1 = require("@config/constants");
const TableHeader = ({ children }) => {
    return ((0, jsx_runtime_1.jsx)("th", Object.assign({ className: "text-uppercase small text-gray-400 fw-medium", scope: "col" }, { children: children })));
};
exports.TableHeader = TableHeader;
const TableSectionTotal = ({ children, title, }) => {
    return ((0, jsx_runtime_1.jsxs)("tr", Object.assign({ className: "text-dark" }, { children: [(0, jsx_runtime_1.jsx)("th", Object.assign({ scope: "row" }, { children: (0, jsx_runtime_1.jsx)("span", Object.assign({ className: "fw-semibold" }, { children: title })) })), children] })));
};
exports.TableSectionTotal = TableSectionTotal;
const TableSectionHeader = ({ children }) => {
    const colHeaders = constants_1.FINANCIAL_TABLE_YEARS;
    return ((0, jsx_runtime_1.jsx)("thead", { children: (0, jsx_runtime_1.jsxs)("tr", Object.assign({ className: "border-bottom border-light" }, { children: [(0, jsx_runtime_1.jsx)("th", Object.assign({ scope: "col", className: "small pt-5 pb-3 fw-semibold text-dark text-uppercase" }, { children: children })), colHeaders.map((year) => {
                    return ((0, jsx_runtime_1.jsxs)("th", Object.assign({ className: "small pt-5 pb-3 text-uppercase text-end", scope: "col" }, { children: ["Year ", year] }), `other-year-header-${year}`));
                })] })) }));
};
exports.TableSectionHeader = TableSectionHeader;
const Table = ({ children, className, borderless = false }) => {
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: "table-responsive" }, { children: (0, jsx_runtime_1.jsx)("table", Object.assign({ className: `table ${borderless ? 'table-borderless' : ''}  ${className ? className : ''}` }, { children: children })) })));
};
exports.default = Table;
