"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const axios_1 = require("axios");
const api_1 = require("@lib/api");
const constants_1 = require("@config/constants");
function useSendVerification() {
    const [cooldown, setCooldown] = (0, react_1.useState)(0);
    const [isDisabled, setIsDisabled] = (0, react_1.useState)(false);
    const [isFetching, setIsFetching] = (0, react_1.useState)(false);
    const requestVerificationCode = (0, react_1.useCallback)(() => __awaiter(this, void 0, void 0, function* () {
        var _a, _b;
        try {
            setIsFetching(true);
            const { data } = yield api_1.nonApiService.request(`${constants_1.PAGE_SIGNUP_VERIFY}`, 'PATCH');
            setCooldown(data.lock_for);
        }
        catch (error) {
            if (error instanceof axios_1.AxiosError) {
                const loggedTime = new Date((_a = error.response) === null || _a === void 0 ? void 0 : _a.data.logged_time).getTime();
                const currentTime = new Date((_b = error.response) === null || _b === void 0 ? void 0 : _b.data.current_time).getTime();
                const timeDelta = Math.trunc((loggedTime - currentTime) / 1000);
                setCooldown(timeDelta);
            }
        }
        finally {
            setIsFetching(false);
        }
    }), []);
    (0, react_1.useMemo)(() => {
        setIsDisabled(cooldown !== 0);
    }, [cooldown]);
    (0, react_1.useEffect)(() => {
        cooldown > 0 && setTimeout(() => setCooldown(cooldown - 1), 1000);
    }, [cooldown]);
    return {
        requestVerificationCode,
        cooldown,
        isDisabled,
        isFetching,
    };
}
exports.default = useSendVerification;
