"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = __importStar(require("react"));
const chart_js_1 = require("chart.js");
chart_js_1.Chart.register(chart_js_1.BarElement, chart_js_1.BarController, chart_js_1.LinearScale, chart_js_1.CategoryScale, chart_js_1.LineElement, chart_js_1.LineController, chart_js_1.PointElement, chart_js_1.Tooltip, chart_js_1.Title, chart_js_1.SubTitle);
const Chart = react_1.default.forwardRef(({ id, data, type, plugins, caption, title }, ref) => {
    (0, react_1.useEffect)(() => {
        var _a;
        let delayed = false;
        if ((_a = ref.current) === null || _a === void 0 ? void 0 : _a.destroy)
            ref.current.destroy();
        ref.current = new chart_js_1.Chart(id, {
            type: type,
            data: data,
            options: {
                responsive: true,
                aspectRatio: 2,
                maintainAspectRatio: false,
                elements: {
                    line: {
                        tension: 0.4
                    }
                },
                animation: {
                    onComplete: () => {
                        delayed = true;
                    },
                    delay: context => {
                        let delay = 0;
                        if (context.type === 'data' &&
                            context.mode === 'default' &&
                            !delayed) {
                            delay = context.dataIndex * 300 + context.datasetIndex * 100;
                        }
                        return delay;
                    }
                },
                plugins: Object.assign(Object.assign({ tooltip: {}, legend: { display: false } }, plugins), { title: {
                        display: false
                    }, subTitle: {
                        display: false
                    } })
            }
        });
    }, []);
    const ui = (0, react_1.useMemo)(() => {
        return ((0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "col container bg-white rounded shadow-sm p-2 p-md-5" }, { children: [(0, jsx_runtime_1.jsx)("div", Object.assign({ className: "display-6 fw-semibold text-center text-md-start mb-3" }, { children: title })), (0, jsx_runtime_1.jsxs)("figure", { children: [(0, jsx_runtime_1.jsx)("canvas", { className: "chart-basic", id: id, width: "100%" }), caption && ((0, jsx_runtime_1.jsx)("figcaption", Object.assign({ className: "mice mt-3" }, { children: caption })))] })] })));
    }, []);
    return ui;
});
exports.default = Chart;
