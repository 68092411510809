"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chapters = void 0;
exports.chapters = [
    {
        id: 1,
        time: 0,
        timestamp: '0:00',
        title: 'What is SHARE and what problem are we solving?',
    },
    {
        id: 2,
        time: 29,
        timestamp: '0:29',
        title: 'Why you should invest in the US Single-Family Rental (SFR) market',
    },
    {
        id: 3,
        time: 139,
        timestamp: '2:19',
        title: 'How SHARE simplifies the end-to-end investing process',
    },
    {
        id: 4,
        time: 182,
        timestamp: '3:02',
        title: 'Meet the SHARE Founding Team',
    },
    {
        id: 5,
        time: 217,
        timestamp: '3:37',
        title: "Overview of SHARE's End-to-End Process",
    },
    {
        id: 6,
        time: 321,
        timestamp: '5:21',
        title: 'Step 1 - Understanding your Buy Box',
    },
    {
        id: 7,
        time: 401,
        timestamp: '6:41',
        title: 'Step 2 - Financing Strategy',
    },
    {
        id: 8,
        time: 427,
        timestamp: '7:07',
        title: 'Step 3 - Tax & Entity Structure',
    },
    {
        id: 9,
        time: 472,
        timestamp: '7:52',
        title: 'Step 4 - Custom Property Search & Acquire',
    },
    {
        id: 10,
        time: 544,
        timestamp: '9:04',
        title: 'Step 5 - Renovate & Lease',
    },
    { id: 11, time: 571, timestamp: '9:31', title: 'Step 6 - Collect!' },
    {
        id: 12,
        time: 604,
        timestamp: '10:04',
        title: 'SHARE Fee Structure',
    },
    {
        id: 13,
        time: 686,
        timestamp: '11:26',
        title: 'SHARE Fee Structure - Subscription fee for mentorship',
    },
];
