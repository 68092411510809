"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const InvestorReferrals = ({ referral_url }) => {
    const [feedbackText, setFeedbackText] = (0, react_1.useState)('Click to copy');
    const copyReferralUrl = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield navigator.clipboard.writeText(referral_url);
            setFeedbackText('Copied!');
            setTimeout(() => {
                setFeedbackText('Click to copy');
            }, 2000);
        }
        catch (error) {
            console.error('failed top copy clipboard', error);
        }
    });
    return ((0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "row flex-column text-center" }, { children: [(0, jsx_runtime_1.jsx)("div", Object.assign({ className: "col" }, { children: (0, jsx_runtime_1.jsx)("h3", { children: "Your unique referral url" }) })), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: "col border border-light mb-3" }, { children: (0, jsx_runtime_1.jsx)("button", Object.assign({ className: "btn", onClick: copyReferralUrl }, { children: (0, jsx_runtime_1.jsx)("span", Object.assign({ className: "lead fw-semibold text-secondary", id: "referral-url-value" }, { children: referral_url })) })) })), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: "col" }, { children: (0, jsx_runtime_1.jsx)("p", Object.assign({ className: "small" }, { children: feedbackText })) }))] })));
};
exports.default = InvestorReferrals;
