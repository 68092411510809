"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const constants_1 = require("../../config/constants");
require("./LoomVideoPlaylist.module.scss");
const LoomVideoPlaylist = ({ videoId, chapters, }) => {
    const getLoomUrl = (timeStamp) => `${constants_1.LOOM_EMBED_URL}${videoId}?hideEmbedTopBar=true${timeStamp ? `&t=${timeStamp}` : ``}`;
    const [loomUrl, setLoomUrl] = (0, react_1.useState)(getLoomUrl(null));
    return ((0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "row" }, { children: [(0, jsx_runtime_1.jsx)("div", Object.assign({ className: "col-lg-8 mb-4 mb-lg-0" }, { children: (0, jsx_runtime_1.jsx)("div", Object.assign({ style: {
                        position: 'relative',
                        paddingBottom: '54.52631578947368%',
                        height: '100%',
                        maxHeight: '500px',
                    } }, { children: (0, jsx_runtime_1.jsx)("iframe", { src: loomUrl, allowFullScreen: true, style: {
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            width: '100%',
                            height: '100%',
                        } }, loomUrl) })) })), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: "col", style: { overflowY: 'scroll', maxHeight: '500px' } }, { children: (0, jsx_runtime_1.jsx)("ul", Object.assign({ className: "list-unstyled" }, { children: chapters.map((i) => ((0, jsx_runtime_1.jsx)("li", Object.assign({ className: "py-2 border-bottom" }, { children: (0, jsx_runtime_1.jsxs)("a", Object.assign({ className: "d-flex text-gray-500 gap-2", type: "button", onClick: () => setLoomUrl(getLoomUrl(i.time)) }, { children: [(0, jsx_runtime_1.jsxs)("p", Object.assign({ className: "small fw-bold" }, { children: [i.id, "."] })), (0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)("p", Object.assign({ className: "mb-1 fw-semibold small" }, { children: i.title })), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "text-gray-400 d-flex align-items-center gap-1" }, { children: [(0, jsx_runtime_1.jsx)("i", { className: "bi bi-camera-video" }), ' ', (0, jsx_runtime_1.jsx)("span", Object.assign({ className: "small" }, { children: i.timestamp }))] }))] })] })) }), i.time))) })) }))] })));
};
exports.default = LoomVideoPlaylist;
