"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
function Hero(props) {
    const { children, src } = props;
    const bgImg = {
        background: `url(${src})`,
        width: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
    };
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)("header", Object.assign({ className: "container" }, { children: (0, jsx_runtime_1.jsx)("div", Object.assign({ className: "row mb-4 mb-md-5" }, { children: (0, jsx_runtime_1.jsx)("div", Object.assign({ className: "col position-relative hero-image rounded mt-n1", style: bgImg }, { children: children })) })) })) }));
}
exports.default = Hero;
