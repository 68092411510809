"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlossaryContextProvider = exports.ControlPanelConsumer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const GlossaryContext = (0, react_1.createContext)({ glossary: {} });
exports.ControlPanelConsumer = GlossaryContext.Consumer;
const GlossaryContextProvider = ({ children, glossary, }) => {
    return ((0, jsx_runtime_1.jsx)(GlossaryContext.Provider, Object.assign({ value: {
            glossary,
        } }, { children: children })));
};
exports.GlossaryContextProvider = GlossaryContextProvider;
exports.default = GlossaryContext;
