"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const js_1 = require("design-system/js");
const Carousel = ({ id, images, interval = 5000 }) => {
    const carouselRef = (0, react_1.useRef)();
    const [, setCarousel] = (0, react_1.useState)();
    (0, react_1.useEffect)(() => {
        if (carouselRef.current) {
            setCarousel(new js_1.Carousel(carouselRef.current));
            return () => { };
        }
    }, []);
    const ThumbnailBrowser = () => {
        return ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: "carousel-indicators carousel-indicators__image justify-content-center" }, { children: images.map(({ id: imageId, image }, index) => {
                const style = {
                    background: `url(${image})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                };
                return ((0, jsx_runtime_1.jsx)("button", { type: "button", "data-bs-target": `#${id}`, "data-bs-slide-to": index, className: `${index == 0 ? 'active' : ''}`, "aria-current": `${index == 0 ? 'true' : 'false'}`, "aria-label": `Slide ${index + 1}`, style: style }, `property-image-btn-${imageId}`));
            }) })));
    };
    const InnerGallery = () => {
        return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: images.map(({ id: imageId, description, image }, index) => {
                return ((0, jsx_runtime_1.jsxs)("div", Object.assign({ className: `carousel-item ${index == 0 ? 'active' : ''}` }, { children: [(0, jsx_runtime_1.jsx)("img", { src: image, className: "d-block", alt: description }), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: "text-center mt-4" }, { children: (0, jsx_runtime_1.jsx)("p", Object.assign({ className: "small" }, { children: description })) }))] }), `property-image-${imageId}`));
            }) }));
    };
    const CarouselButton = ({ scrollDirection }) => {
        const scrollIcon = scrollDirection === 'prev' ? 'left-fill' : 'right-fill';
        const scrollAria = scrollDirection === 'prev' ? 'Previous' : 'Next';
        return ((0, jsx_runtime_1.jsxs)("button", Object.assign({ className: `carousel-control-${scrollDirection}`, type: "button", "data-bs-target": `#${id}`, "data-bs-slide": scrollDirection }, { children: [(0, jsx_runtime_1.jsx)("span", Object.assign({ className: "btn btn-sm rounded-pill btn-light", "aria-hidden": "true" }, { children: (0, jsx_runtime_1.jsx)("i", { className: `bi bi-caret-${scrollIcon} text-gray-400` }) })), (0, jsx_runtime_1.jsx)("span", Object.assign({ className: "visually-hidden" }, { children: scrollAria }))] })));
    };
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: "container" }, { children: (0, jsx_runtime_1.jsx)("div", Object.assign({ className: "row" }, { children: (0, jsx_runtime_1.jsx)("div", Object.assign({ className: "col" }, { children: (0, jsx_runtime_1.jsxs)("div", Object.assign({ id: id, className: "carousel slide", "data-bs-ride": "carousel", ref: (ref) => {
                        carouselRef.current = ref;
                    }, "data-bs-keyboard": false, "data-bs-interval": interval }, { children: [(0, jsx_runtime_1.jsx)(ThumbnailBrowser, {}), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: "carousel-inner" }, { children: (0, jsx_runtime_1.jsx)(InnerGallery, {}) })), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "d-none d-md-block" }, { children: [(0, jsx_runtime_1.jsx)(CarouselButton, { scrollDirection: "prev" }), (0, jsx_runtime_1.jsx)(CarouselButton, { scrollDirection: "next" })] }))] })) })) })) })));
};
exports.default = Carousel;
