"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const bootstrap_1 = require("bootstrap");
const Tooltip = ({ as: Trigger, content, className = '', placement = 'right', toggle = 'tooltip', darkMode = false, }) => {
    const [tooltip, setTooltip] = (0, react_1.useState)();
    const ref = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        if ((ref === null || ref === void 0 ? void 0 : ref.current) && !tooltip) {
            const toolTipInstance = new bootstrap_1.Tooltip(ref.current, {
                title: content,
                placement,
            });
            setTooltip(toolTipInstance);
        }
        return () => {
            tooltip === null || tooltip === void 0 ? void 0 : tooltip.dispose();
        };
    }, [content, placement, tooltip]);
    return ((0, jsx_runtime_1.jsx)(Trigger, { className: `${className} ${darkMode ? '' : 'tooltip-light'}`, ref: ref, "data-bs-toggle": toggle, "data-bs-placement": placement, "data-bs-html": "true", "data-bs-title": content }));
};
exports.default = Tooltip;
