"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useHandleClickOutside(ref, onClickOutside) {
    (0, react_1.useEffect)(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, onClickOutside]);
}
exports.default = useHandleClickOutside;
