"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const StarRating = ({ rating }) => {
    const fullStars = Math.floor(rating);
    const halfStars = rating % 1;
    const blankStars = Math.floor(5 - fullStars - halfStars);
    const stars = (0, react_1.useMemo)(() => {
        const starsArr = [];
        for (let i = 0; i < fullStars; i++) {
            starsArr.push('bi-star-fill');
        }
        if (halfStars > 0) {
            starsArr.push('bi-star-half');
        }
        for (let j = 0; j < blankStars; j++) {
            starsArr.push('bi-star');
        }
        return starsArr;
    }, [rating]);
    return ((0, jsx_runtime_1.jsx)("span", Object.assign({ className: "small", "aria-describedby": `Rating: ${rating}` }, { children: stars.map((starClass, index) => {
            return ((0, jsx_runtime_1.jsx)("span", { className: `bi ${starClass} text-yellow-600` }, index));
        }) })));
};
exports.default = StarRating;
