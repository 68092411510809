"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function saveTrackingParams(extracted_params) {
    const queryParams = new URLSearchParams(window.location.search);
    const paramsArray = extracted_params['extracted_params']
        .split(', ')
        .map((key) => key.trim());
    if (queryParams.get('ref_spid') != null) {
        let date = new Date();
        date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
        const expires = 'expires=' + date.toUTCString();
        document.cookie =
            'ref_spid=' +
                queryParams.get('ref_spid') +
                ';expires=' +
                expires +
                ';path=/';
    }
    paramsArray.forEach((param) => {
        const paramValue = queryParams.get(param);
        const storageValue = localStorage.getItem(param);
        if (paramValue && storageValue === null) {
            localStorage.setItem(param, paramValue);
        }
    });
}
exports.default = saveTrackingParams;
