"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const components_1 = require("@kepler/components");
const constants_1 = require("@config/constants");
const chapters_1 = require("./chapters");
const DashboardGetStarted = () => {
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)("div", Object.assign({ className: "row" }, { children: (0, jsx_runtime_1.jsx)("div", Object.assign({ className: "col" }, { children: (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "p-4 border border-gray-300 rounded bg-white" }, { children: [(0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "row pb-3" }, { children: [(0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "col d-flex align-items-center pb-2" }, { children: [(0, jsx_runtime_1.jsx)("h5", Object.assign({ className: "mb-0" }, { children: "What is SHARE?" })), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: "ps-2" }, { children: (0, jsx_runtime_1.jsxs)(components_1.Badge, Object.assign({ className: "bg-gray-400" }, { children: [(0, jsx_runtime_1.jsx)("i", { className: "bi bi-clock-fill pe-1" }), "15 Minutes"] })) }))] })), (0, jsx_runtime_1.jsx)("p", { children: "This video covers important topics and concepts that will be discussed further during your call such as how SHARE works, the processes involved, and how to invest using the SHARE platform." })] })), (0, jsx_runtime_1.jsx)(components_1.LoomVideoPlaylist, { videoId: constants_1.LOOM_SHARE_PROCESS_VIDEO_ID, chapters: chapters_1.chapters })] })) })) })) }));
};
exports.default = DashboardGetStarted;
