"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPropertyBadgeStyle = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const getPropertyBadgeStyle = (status) => {
    switch (status) {
        case 'AVAILABLE': {
            return {
                textClasses: 'bg-cyan-300 text-cyan-700',
                label: 'Available'
            };
        }
        case 'COMINGSOON': {
            return {
                textClasses: 'bg-yellow-300 text-yellow-700',
                label: 'Coming Soon'
            };
        }
        case 'WAITLIST': {
            return {
                textClasses: 'bg-blue-300 text-blue-700',
                label: 'Reserved'
            };
        }
        case 'RESERVED': {
            return {
                textClasses: 'bg-blue-300 text-blue-700',
                label: 'Reserved'
            };
        }
        case 'SOLD': {
            return {
                textClasses: 'bg-pink-300 text-pink-700',
                label: 'Sold'
            };
        }
        default: {
            return {
                textClasses: 'bg-gray-300 text-gray-700',
                label: status
            };
        }
    }
};
exports.getPropertyBadgeStyle = getPropertyBadgeStyle;
const PropertyStatusBadge = ({ status, className = '' }) => {
    const styles = (0, react_1.useMemo)(() => {
        return (0, exports.getPropertyBadgeStyle)(status);
    }, [status]);
    return ((0, jsx_runtime_1.jsx)("span", Object.assign({ className: `badge fw-semibold rounded-pill ${styles.textClasses} ${className}` }, { children: styles.label })));
};
exports.default = PropertyStatusBadge;
