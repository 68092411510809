"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const components_1 = require("@kepler/components");
const Card_1 = require("@kepler/components/Card");
const InvestorProperties = ({ properties }) => {
    return ((0, jsx_runtime_1.jsx)(components_1.Grid, Object.assign({ columns: 4 }, { children: !(properties === null || properties === void 0 ? void 0 : properties.length) ? ((0, jsx_runtime_1.jsx)(Card_1.CardEmpty, Object.assign({ bodyText: "You currently do not own any properties" }, { children: (0, jsx_runtime_1.jsx)("a", Object.assign({ className: "btn btn-secondary btn-sm", href: "/listings" }, { children: "Browse Properties" })) }))) : ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: properties.map((property) => {
                return ((0, jsx_runtime_1.jsx)(Card_1.CardProperty, { property: property, url: "/account/properties" }, property.id));
            }) })) })));
};
exports.default = InvestorProperties;
