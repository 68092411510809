"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const js_1 = require("design-system/js");
const react_1 = require("react");
function Collapse({ id, className = '', buttonWrapperClassName = '', buttonClassName = '', children, }) {
    const collapseRef = (0, react_1.useRef)();
    const [collapse, setCollapse] = (0, react_1.useState)();
    (0, react_1.useEffect)(() => {
        if (collapseRef === null || collapseRef === void 0 ? void 0 : collapseRef.current) {
            setCollapse(new js_1.Collapse(collapseRef.current, {}));
        }
    }, []);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("div", Object.assign({ className: ` ${buttonWrapperClassName}` }, { children: (0, jsx_runtime_1.jsx)("button", Object.assign({ className: `${buttonClassName}`, type: "button", "data-bs-toggle": "collapse", "data-bs-target": `#${id}`, "aria-controls": id, "aria-expanded": "false", ref: (ref) => {
                        collapseRef.current = ref;
                    } }, { children: (0, jsx_runtime_1.jsx)("span", { className: "navbar-toggler-icon" }) })) })), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: ` ${className}`, id: id }, { children: children }))] }));
}
exports.default = Collapse;
