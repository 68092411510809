"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Formatter_1 = require("../utils/Formatter/");
function useGetPropertyFeatures(property) {
    return {
        price: Formatter_1.Formatter.byType(property.asset_price, 'fixed-dollar'),
        monthlyRent: Formatter_1.Formatter.byType(property.listing_meta.monthly_rent, 'fixed-dollar'),
        tenYrCashFlow: Formatter_1.Formatter.byType(property.listing_meta.ten_year_cash_flow, 'fixed-dollar'),
        capRate: Formatter_1.Formatter.byType(property.listing_meta.cap_rate, 'percent'),
        rentableSqFt: Formatter_1.Formatter.byType(property.features.fixed_amounts_fmt['Rentable Sq.Ft']),
    };
}
exports.default = useGetPropertyFeatures;
