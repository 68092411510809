"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
function InputSearch({ onSearch }) {
    const [query, setQuery] = (0, react_1.useState)('');
    const [search, setSearch] = (0, react_1.useState)('');
    const onSearchSubmit = (0, react_1.useCallback)((e) => {
        var _a;
        e.preventDefault();
        const value = ((_a = query === null || query === void 0 ? void 0 : query.trim) === null || _a === void 0 ? void 0 : _a.call(query)) || '';
        setSearch(value);
    }, [query]);
    (0, react_1.useEffect)(() => {
        if (typeof search !== 'undefined') {
            onSearch(search);
        }
    }, [search]);
    return ((0, jsx_runtime_1.jsxs)("form", Object.assign({ onSubmit: onSearchSubmit, className: "input-group input-group-sm" }, { children: [(0, jsx_runtime_1.jsx)("input", { type: "text", className: "form-control", placeholder: "Search", "aria-label": "Search", "aria-describedby": "button-addon2", name: "search", value: query, onChange: (e) => {
                    e.preventDefault();
                    const { value } = e.target;
                    setQuery(value);
                } }), (0, jsx_runtime_1.jsx)("button", Object.assign({ disabled: (query === null || query === void 0 ? void 0 : query.trim()) === (search === null || search === void 0 ? void 0 : search.trim()), className: "btn btn-primary btn-sm rounded-end", type: "submit" }, { children: (0, jsx_runtime_1.jsx)("i", { className: "bi bi-search" }) })), (0, jsx_runtime_1.jsx)("button", Object.assign({ className: "ms-2 btn btn-sm btn-primary rounded", type: "button", onClick: () => {
                    setQuery('');
                    setSearch('');
                }, disabled: !((query === null || query === void 0 ? void 0 : query.trim()) || (search === null || search === void 0 ? void 0 : search.trim())) }, { children: "Clear" }))] })));
}
exports.default = InputSearch;
