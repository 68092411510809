"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const LineItemRow_1 = require("./LineItemRow");
const helpers_1 = require("./helpers");
function FinancialsTab(props) {
    const { incomeStatement } = props;
    const hasIncomeStatement = Object.entries(incomeStatement).length;
    const colLength = incomeStatement['cols'].length;
    const minLayoutShift = colLength < helpers_1.DEFAULT_COL_SHIFT;
    const subHeaderLength = colLength * 2;
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: hasIncomeStatement && ((0, jsx_runtime_1.jsxs)("table", Object.assign({ className: "table" }, { children: [(0, jsx_runtime_1.jsx)("thead", { children: (0, jsx_runtime_1.jsxs)("tr", { children: [(0, jsx_runtime_1.jsx)("th", Object.assign({ className: "text-uppercase small text-gray-400 fw-medium" }, { children: "Property Account" })), minLayoutShift && ((0, jsx_runtime_1.jsx)(helpers_1.ColFill, { cols: incomeStatement['cols'].length, type: "header" })), incomeStatement['cols'].map((col) => {
                                return ((0, jsx_runtime_1.jsx)("th", Object.assign({ scope: "col", className: "text-uppercase small text-gray-400 fw-medium" }, { children: col }), col));
                            })] }) }), (0, jsx_runtime_1.jsxs)("tbody", { children: [(0, jsx_runtime_1.jsxs)("tr", { children: [(0, jsx_runtime_1.jsx)("th", Object.assign({ scope: "row", className: "text-uppercase fw-medium small" }, { children: "Income" })), minLayoutShift ? ((0, jsx_runtime_1.jsx)(helpers_1.ColFill, { cols: subHeaderLength, type: "header" })) : ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: incomeStatement['cols'].map((col) => {
                                        return (0, jsx_runtime_1.jsx)("td", {}, col);
                                    }) }))] }), Object.keys(incomeStatement['detailed_income']).map((key) => {
                            return ((0, jsx_runtime_1.jsx)(LineItemRow_1.LineItemRow, { cols: incomeStatement['cols'], lineItems: incomeStatement['detailed_income'][key], title: key }, key));
                        }), (0, jsx_runtime_1.jsx)(LineItemRow_1.LineItemRow, { headerClass: "text-dark fw-medium", cols: incomeStatement['cols'], lineItems: incomeStatement['total_income'], title: "Total Income" }), (0, jsx_runtime_1.jsxs)("tr", { children: [(0, jsx_runtime_1.jsx)("th", Object.assign({ scope: "row", className: "text-uppercase fw-medium small" }, { children: "Expenses" })), minLayoutShift ? ((0, jsx_runtime_1.jsx)(helpers_1.ColFill, { cols: subHeaderLength, type: "header" })) : ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: incomeStatement['cols'].map((col) => {
                                        return (0, jsx_runtime_1.jsx)("td", {}, col);
                                    }) }))] }), Object.keys(incomeStatement['detailed_expenses']).map((key) => {
                            return ((0, jsx_runtime_1.jsx)(LineItemRow_1.LineItemRow, { cols: incomeStatement['cols'], lineItems: incomeStatement['detailed_expenses'][key], title: key }, key));
                        }), (0, jsx_runtime_1.jsx)(LineItemRow_1.LineItemRow, { cols: incomeStatement['cols'], headerClass: "text-dark fw-medium", lineItems: incomeStatement['total_expenses'], title: "Total Expenses" }), (0, jsx_runtime_1.jsx)(LineItemRow_1.LineItemRow, { cols: incomeStatement['cols'], rowClass: "mt-5", headerClass: "text-dark fw-semibold", cellClass: "text-dark fw-semibold", title: "Net Income", lineItems: incomeStatement['net_totals'] })] })] }))) }));
}
exports.default = FinancialsTab;
