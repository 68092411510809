"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColFill = exports.DEFAULT_COL_SHIFT = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
exports.DEFAULT_COL_SHIFT = 3;
const ColFill = ({ cols, type }) => {
    const style = {
        width: '10%',
    };
    const cells = Array.from({ length: cols }, (_, index) => type === 'header' ? ((0, jsx_runtime_1.jsx)("th", { style: style }, index)) : ((0, jsx_runtime_1.jsx)("td", { style: style }, index)));
    return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: cells });
};
exports.ColFill = ColFill;
