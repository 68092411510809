"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const AccordionContext_1 = require("./AccordionContext");
const AccordionItem = ({ title, children, }) => {
    const { activeId, setActiveId } = (0, react_1.useContext)(AccordionContext_1.AccordionContext);
    const handleToggle = () => {
        setActiveId(activeId === title ? null : title);
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("div", Object.assign({ onClick: handleToggle, className: "accordion-item" }, { children: (0, jsx_runtime_1.jsx)("div", Object.assign({ className: "accordion-header" }, { children: (0, jsx_runtime_1.jsx)("button", Object.assign({ type: "button", className: `accordion-button py-4 px-0 ${activeId === title ? '' : 'collapsed'}`, "aria-expanded": activeId === title ? 'true' : 'false' }, { children: (0, jsx_runtime_1.jsx)("p", Object.assign({ className: "fs-5 fw-medium mb-0" }, { children: title })) })) })) })), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: `accordion-collapse ${activeId === title ? 'content show' : 'content'}` }, { children: (0, jsx_runtime_1.jsx)("div", Object.assign({ className: "accordion-body" }, { children: children })) }))] }));
};
exports.default = AccordionItem;
