"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const bootstrap_1 = require("bootstrap");
const DropdownLink = ({ id, autoClose = true, className = '', label = '', buttonClassName = '', menuClassName = '', style = {}, children }) => {
    const dropdownRef = (0, react_1.useRef)();
    const [dropdown, setDropdown] = (0, react_1.useState)();
    (0, react_1.useEffect)(() => {
        var _a, _b, _c, _d;
        if (dropdownRef.current) {
            (_a = dropdownRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('show.bs.dropdown', function () { });
            (_b = dropdownRef.current) === null || _b === void 0 ? void 0 : _b.addEventListener('shown.bs.dropdown', function () { });
            (_c = dropdownRef.current) === null || _c === void 0 ? void 0 : _c.addEventListener('hide.bs.dropdown', function () { });
            (_d = dropdownRef.current) === null || _d === void 0 ? void 0 : _d.addEventListener('hidden.bs.dropdown', function () { });
            return () => {
                var _a, _b, _c, _d, _e;
                (_a = dropdownRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('shown.bs.dropdown', () => { });
                (_b = dropdownRef.current) === null || _b === void 0 ? void 0 : _b.removeEventListener('hidden.bs.dropdown', () => { });
                (_c = dropdownRef.current) === null || _c === void 0 ? void 0 : _c.removeEventListener('show.bs.dropdown', () => { });
                (_d = dropdownRef.current) === null || _d === void 0 ? void 0 : _d.removeEventListener('hide.bs.dropdown', () => { });
                (_e = dropdown === null || dropdown === void 0 ? void 0 : dropdown.dispose) === null || _e === void 0 ? void 0 : _e.call(dropdown);
            };
        }
    }, []);
    (0, react_1.useEffect)(() => {
        if (dropdownRef === null || dropdownRef === void 0 ? void 0 : dropdownRef.current) {
            setDropdown(new bootstrap_1.Dropdown(dropdownRef.current, {
                autoClose
            }));
        }
    }, []);
    return ((0, jsx_runtime_1.jsxs)("div", Object.assign({ className: `${className}` }, { children: [(0, jsx_runtime_1.jsx)("a", Object.assign({ ref: ref => (dropdownRef.current = ref), type: "button", id: id, className: `nav-link ${buttonClassName} dropdown-toggle`, "data-bs-toggle": "dropdown", "aria-expanded": "false", style: style }, { children: label })), (0, jsx_runtime_1.jsx)("ul", Object.assign({ className: `dropdown-menu ${menuClassName}`, "aria-labelledby": id }, { children: children }))] })));
};
exports.default = DropdownLink;
