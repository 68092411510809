"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StrategyBadge = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const components_1 = require("@kepler/components");
const StrategyBadge = ({ asset_strategy }) => {
    var _a;
    const defaultKey = 'defaultKey';
    const strategyType = {
        'Leasing in Progress': 'bg-yellow-300 text-yellow-700',
        Evict: 'bg-pink-300 text-pink-700',
        defaultKey: 'bg-gray-300 text-gray-500',
    };
    const className = (_a = strategyType[asset_strategy]) !== null && _a !== void 0 ? _a : strategyType[defaultKey];
    return ((0, jsx_runtime_1.jsx)(components_1.Badge, Object.assign({ className: className }, { children: asset_strategy }), asset_strategy));
};
exports.StrategyBadge = StrategyBadge;
