"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function forwardRefTokenToView() {
    (0, react_1.useEffect)(() => {
        const referrer = localStorage.getItem('ref_spid');
        const referrerInput = document.getElementById('id_referrer');
        if (referrer) {
            referrerInput.value = referrer;
        }
    });
}
exports.default = forwardRefTokenToView;
