"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const Section = ({ children, className = '', size, style }) => {
    let sizeClass = '';
    switch (size) {
        case 'sm':
            sizeClass = 'py-4';
            break;
        case 'md':
            sizeClass = 'py-5';
            break;
        case 'lg':
            sizeClass = 'py-6 py-lg-8';
            break;
        default:
            sizeClass = 'py-5 py-lg-8';
            break;
    }
    return ((0, jsx_runtime_1.jsx)("section", Object.assign({ className: `container-fluid ${className}`, style: style }, { children: (0, jsx_runtime_1.jsx)("div", Object.assign({ className: `container ${sizeClass}` }, { children: children })) })));
};
exports.default = Section;
