"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RootStateConsumer = exports.RootStateProvider = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const ready = ($host) => {
    const cssNames = {
        loading: false,
    };
    Object.entries(cssNames).forEach(([cssName, val]) => {
        if (!!val) {
            if (!$host.hasClass(cssName))
                $host.addClass(cssName);
        }
        else {
            if ($host.hasClass(cssName))
                $host.removeClass(cssName);
        }
    });
};
const defaultSettings = {
    authenticated: false,
    ready: () => undefined,
};
const RootStateContext = (0, react_1.createContext)(defaultSettings);
const RootStateProvider = ({ children, authenticated, $host, }) => {
    const onReady = (0, react_1.useCallback)(() => {
        if ($host) {
            ready($host);
        }
    }, [$host]);
    return ((0, jsx_runtime_1.jsx)(RootStateContext.Provider, Object.assign({ value: {
            authenticated: authenticated,
            ready: onReady,
        } }, { children: children })));
};
exports.RootStateProvider = RootStateProvider;
exports.RootStateConsumer = RootStateContext.Consumer;
exports.default = RootStateContext;
