"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const Grid = ({ children, className, columns, gutter = 4 }) => {
    let columnClass;
    switch (columns) {
        case 1:
            columnClass = 'row-cols-1';
            break;
        case 2:
            columnClass = 'row-cols-1 row-cols-md-2';
            break;
        case 3:
            columnClass = 'row-cols-1 row-cols-md-2 row-cols-lg-3';
            break;
        case 4:
            columnClass = 'row-cols-1 row-cols-md-2 row-cols-xl-4';
            break;
        default:
            columnClass = '';
    }
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: `row ${columnClass} g-${gutter} ${className}` }, { children: children })));
};
exports.default = Grid;
