"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function useGetPropertyPrimaryImage(imageSet) {
    const hasNoImages = {
        image: `${webpack_static}/ds/assets/img/no-image-banner.png`,
        description: 'No image exists for this property',
    };
    const hasPrimary = imageSet.find((image) => image.is_primary === true);
    const firstAvailable = imageSet === null || imageSet === void 0 ? void 0 : imageSet[0];
    const primary = hasPrimary || firstAvailable || hasNoImages;
    return primary.image;
}
exports.default = useGetPropertyPrimaryImage;
