"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useNicheGrades(property) {
    const insightData = property.insight_data;
    const insightType = property.insight_data.display;
    if (insightData === null) {
        return {};
    }
    const getGrades = (obj) => {
        const data = Object.fromEntries(Object.entries(obj).filter(([key]) => key.startsWith('grade_')));
        return typeof data !== undefined ? data : {};
    };
    const grades = (0, react_1.useMemo)(() => {
        switch (insightType) {
            case 'PTL_NBHD':
                return getGrades(property.insight_data.town_grades);
            case 'PTL_ZIP':
                return getGrades(property.insight_data.zip_grades);
            case 'PTL_NONE':
            default:
                return {};
        }
    }, [property.insight_data.display]);
    return grades;
}
exports.default = useNicheGrades;
