"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findByDataCy = exports.findAllByDataCy = exports.getAllByDataCy = exports.getByDataCy = exports.queryAllByDataCy = exports.queryByDataCy = void 0;
const react_1 = require("@testing-library/react");
const queryAllByDataCy = (container, id, options) => react_1.queryHelpers.queryAllByAttribute('aria-describedby', container, id, options);
exports.queryAllByDataCy = queryAllByDataCy;
const getMultipleError = (c, dataCyValue) => `Found multiple elements with the aria-describedby attribute of: ${dataCyValue}`;
const getMissingError = (c, dataCyValue) => `Unable to find an element with the aria-describedby attribute of: ${dataCyValue}`;
const [queryByDataCy, getAllByDataCy, getByDataCy, findAllByDataCy, findByDataCy] = (0, react_1.buildQueries)(queryAllByDataCy, getMultipleError, getMissingError);
exports.queryByDataCy = queryByDataCy;
exports.getAllByDataCy = getAllByDataCy;
exports.getByDataCy = getByDataCy;
exports.findAllByDataCy = findAllByDataCy;
exports.findByDataCy = findByDataCy;
