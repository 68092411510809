"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_stripe_js_1 = require("@stripe/react-stripe-js");
const CheckoutForm = ({ subscriptionId }) => {
    const stripe = (0, react_stripe_js_1.useStripe)();
    const elements = (0, react_stripe_js_1.useElements)();
    const [error, setError] = (0, react_1.useState)();
    const [loading, setLoading] = (0, react_1.useState)(false);
    const [ready, setReady] = (0, react_1.useState)(false);
    const protocol = window.location.protocol;
    const domain = window.location.hostname;
    const port = window.location.port;
    const full = `${protocol}//${domain}:${port ? port : ''}`;
    const addressOptions = {
        mode: 'billing',
    };
    (0, react_1.useEffect)(() => {
        if (!stripe) {
            return;
        }
        if (!ready)
            setReady(true);
    }, [stripe]);
    const handleSubmit = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        setLoading(true);
        if (!stripe || !elements) {
            return;
        }
        const { error: stripeError } = yield stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${full}/wealthbuilder/checkout/success?subscription_id=${subscriptionId}`,
            },
        });
        if (stripeError.type === 'card_error' ||
            stripeError.type === 'validation_error') {
            setError(stripeError.message);
        }
        else {
            setError(`Your payment cannot be processed. Please try again later or with a different card.`);
        }
        setLoading(false);
    });
    return ((0, jsx_runtime_1.jsxs)("form", Object.assign({ id: "payment-form", onSubmit: handleSubmit }, { children: [(0, jsx_runtime_1.jsx)(react_stripe_js_1.AddressElement, { options: addressOptions, className: "pb-4" }), (0, jsx_runtime_1.jsx)(react_stripe_js_1.PaymentElement, { id: 'payment-element' }), stripe && elements && ready && ((0, jsx_runtime_1.jsxs)("button", Object.assign({ disabled: loading, className: "btn btn-primary btn mt-4 small d-flex align-items-center", type: "submit" }, { children: [(0, jsx_runtime_1.jsx)("small", Object.assign({ className: "me-1" }, { children: "Pay" })), loading ? ((0, jsx_runtime_1.jsx)("span", Object.assign({ className: "d-inline-block spinner-border float-end spinner-border-sm", role: "status" }, { children: (0, jsx_runtime_1.jsx)("span", Object.assign({ className: "visually-hidden" }, { children: "Paying..." })) }))) : ((0, jsx_runtime_1.jsx)("span", { className: "bi bi-cart4" }))] }))), error && ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: "mt-3" }, { children: (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "alert alert-danger alert-dismissible d-flex align-items-center small", role: "alert" }, { children: [(0, jsx_runtime_1.jsx)("button", { type: "button", className: "btn-close", "data-bs-dismiss": "alert", "aria-label": "close" }), (0, jsx_runtime_1.jsx)("div", { children: error })] })) })))] })));
};
exports.default = CheckoutForm;
