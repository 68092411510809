"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_2 = require("react");
const PropertyListCarousel = ({ children, title, href, buttonText, }) => {
    const [scrollPosition, setScrollPosition] = (0, react_2.useState)(0);
    const [showNextButton, setShowNextButton] = (0, react_2.useState)(false);
    const [showPrevButton, setShowPrevButton] = (0, react_2.useState)(false);
    const scrollRef = (0, react_2.useRef)(null);
    const handleScroll = (ref, direction) => {
        if (ref.current) {
            const scrollValue = 300;
            direction === 'right'
                ? (ref.current.scrollLeft += scrollValue)
                : (ref.current.scrollLeft -= scrollValue);
        }
    };
    const ScrollButton = ({ direction }) => {
        return ((0, jsx_runtime_1.jsx)("button", Object.assign({ className: `btn btn-sm shadow-sm btn-primary d-none d-sm-block ${direction === 'right'
                ? 'property_list_carousel--btn-right'
                : 'property_list_carousel--btn-left'}`, onClick: () => handleScroll(scrollRef, direction) }, { children: (0, jsx_runtime_1.jsx)("i", { className: `bi ${direction === 'right' ? 'bi-chevron-right' : 'bi-chevron-left'}` }) })));
    };
    const calcScrollXDistanceMax = (0, react_2.useMemo)(() => (ref) => {
        return ref.current
            ? ref.current.scrollWidth - ref.current.clientWidth
            : null;
    }, []);
    const scrollDistance = {
        min: 0,
        max: calcScrollXDistanceMax(scrollRef),
    };
    (0, react_1.useLayoutEffect)(() => {
        scrollPosition === scrollDistance.max
            ? setShowNextButton(scrollPosition !== scrollDistance.max)
            : setShowNextButton(true);
        scrollPosition === scrollDistance.min
            ? setShowPrevButton(scrollPosition !== scrollDistance.min)
            : setShowPrevButton(true);
    }, [scrollPosition, scrollRef.current]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "d-flex align-items-baseline pb-3" }, { children: [(0, jsx_runtime_1.jsx)("p", Object.assign({ className: "mice text-gray-400 fw-semibold text-uppercase" }, { children: title })), href ? ((0, jsx_runtime_1.jsx)("a", Object.assign({ className: "ps-2 small fw-medium", href: href }, { children: buttonText }))) : ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}))] })), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "property_list_carousel" }, { children: [showPrevButton && (0, jsx_runtime_1.jsx)(ScrollButton, { direction: "left" }), showNextButton && (0, jsx_runtime_1.jsx)(ScrollButton, { direction: "right" }), (0, jsx_runtime_1.jsx)("div", Object.assign({ onScroll: (e) => setScrollPosition(e.currentTarget.scrollLeft), className: "row row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-4 flex-nowrap property_list_carousel--inner", ref: scrollRef }, { children: children }))] }))] }));
};
exports.default = PropertyListCarousel;
